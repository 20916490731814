<template>
  <section id="edit-products-shop">
    <b-tabs
      v-if="mostrarDatos"
      pills
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>{{ $t("contents.categoryData") }}</span>
        </template>
        <b-row class="match-height mt-2">
          <b-col>
            <b-overlay
              variant="transparent"
              :show="showData"
            >
              <b-card>
                <b-card-header>
                  <b-card-title>{{ $t("contents.categoryData") }}</b-card-title>
                </b-card-header>
                <b-card-body>
                  <b-row>
                    <b-col
                      lg="11"
                      class="ml-2 mb-2"
                    >
                      <b-form>
                        <!-- form input -->
                        <b-form-group
                          class="mb-2 mr-1"
                          :label="$t('firstName')"
                        >
                          <b-form-input
                            v-model="name"
                            :placeholder="$t('nameProduct')"
                            maxlength="150"
                            disabled
                            class="mr-1"
                          />
                          <!-- <small>{{10-name.length}}/10</small> -->
                        </b-form-group>
                        <b-form-group
                          class="mb-2 mr-1"
                          :label="$t('dataGeneric.description')"
                        >
                          <b-form-textarea
                            v-model="description"
                            disabled
                            class="mr-1"
                            :placeholder="$t('dataGeneric.description')"
                          />
                        </b-form-group>

                        <b-form-row
                          v-if="language == base"
                          class="align-items-center"
                        >
                          <b-col
                            md="3"
                            class="mr-1"
                          >
                            <b-form-group :label="$t('externalRef')">
                              <b-form-input
                                id="reference"
                                v-model="reference"
                                maxlength="150"
                                disabled
                                value="reference"
                                :placeholder="$t('externalRefProduct')"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col md="2">
                            <b-form-group :label="$t('message.tableHeader.status')">
                              <b-form-select
                                id="contentStatus"
                                v-model="contentStatus"
                                disabled
                                :label="$t('message.tableHeader.status')"
                              >
                                <b-form-select-option
                                  v-for="s in status"
                                  :key="s.value"
                                  :value="s.value"
                                >
                                  {{ s.text }}
                                </b-form-select-option>
                              </b-form-select>
                            </b-form-group>
                          </b-col>
                          <b-col md="3">
                            <b-form-group :label="$t('salePrice') + '  ( ' + currencyType[currency] + ' )'">
                              <b-form-input
                                id="price"
                                v-model="price"
                                type="number"
                                :placeholder="$t('salePrice')"
                                step="0.01"
                                class="mr-1"
                                disabled
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              v-model="isSpecial"
                              switch
                              disabled
                            >
                              {{ $t('isSpecialProduct') }}
                            </b-form-checkbox>
                          </b-col>
                        </b-form-row>
                        <b-form-row class="align-items-center">
                          <b-col md="11">
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('productTags')"
                            >
                              <v-select
                                v-model="selected"
                                disabled
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                multiple
                                taggable
                                push-tags
                                :placeholder="$t('addTagsC')"
                                @input="modalTags()"
                              />
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="Edit2Icon" />
          <span>{{ $t('seo') }}</span>
        </template>
        <b-overlay
          variant="transparent"
          :show="showSeo"
        >
          <b-card>
            <b-card-header>
              <b-card-title>{{ $t('seo') }}</b-card-title>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col
                  lg="12"
                  class="ml-2 mb-2"
                >
                  <b-form @submit.prevent="updateSeo">
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('title')"
                    >
                      <b-form-input
                        v-model="seoTitle"
                        :placeholder="$t('title')"
                        class="mr-1"
                        disabled
                        maxlength="80"
                      />
                    </b-form-group>

                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('dataGeneric.description')"
                    >
                      <b-form-input
                        v-model="seoDescription"
                        :placeholder="$t('dataGeneric.description')"
                        maxlength="180"
                        disabled
                        class="mr-1"
                      />
                    </b-form-group>
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('friendUrl')"
                    >
                      <b-form-input
                        v-model="friendlyUrl"
                        :placeholder="$t('friendUrl')"
                        disabled
                        class="mr-1"
                      />
                    </b-form-group>

                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('converFriendUrl')"
                    >
                      {{ friendlyUrlValue }}
                    </b-form-group>
                  </b-form>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-tab>
    </b-tabs>
    <b-modal
      ref="modalCard"
      size="xl"
      hide-footer
      :title="$t('modal.card')"
    >
      <div class="d-block text-center">
        <card-selector-modal
          :seleccionado="idSelectCard"
          @id="SelectCardId"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <b-modal
      ref="vend-modal"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
    >
      <div class="d-block text-center">
        <vendor-selector @vendor="selectVendor" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>
import { showToast, messageError, base64Encode } from '@/store/functions'

import {
  BForm,
  BFormCheckbox,
  BFormInput,
  BFormSelectOption,
  BFormGroup,
  BFormRow,
  BFormTextarea,
  BRow,
  BCol,
  BCard,
  BButton,
  BFormSelect,
  BTabs, BTab,
  BCardHeader,
  BCardBody,
  VBTooltip,
  BCardTitle,
  BOverlay,
} from 'bootstrap-vue'

import axios from '@axios'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import VendorSelector from '../../../common/VendorSelectorModal.vue'
import CardSelectorModal from '../../../common/CardSelectorModal.vue'

export default {
  components: {
    BRow,
    BTabs,
    BTab,
    BCol,
    BCard,
    BButton,
    VendorSelector,
    BFormSelect,
    BForm,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    BFormSelectOption,
    BFormRow,
    BFormTextarea,
    CardSelectorModal,
    BCardHeader,
    BCardBody,
    vSelect,
    BCardTitle,
    BOverlay,
  },

  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    language: {
      type: String,
      default: null,
    },
    base: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isSpecial: false,
      viewName: this.$t('editProductShop'),
      viewDetail: this.$t('configProductShop'),
      vendorName: null,
      vendorId: null,
      currencyType: {
        EUR: '€',
        USD: '$',
        GBP: '£',
        ARS: this.$t('argentinePeso'),
      },
      currency: null,
      labelStatus: 'RED',
      contentStatus: 'RED',
      price: null,
      deleteFile: [],
      status: [
        { value: 'RED', text: `🔴 ${this.$t('code.desactivado')}` },
        { value: 'YEW', text: `🟡 ${this.$t('dataGeneric.internal')}` },
        { value: 'GRE', text: `🟢 ${this.$t('ecommerce.public')}` },
      ],
      msgFinal: null,
      userData: getUserData(),
      id: this.$route.params.id,
      idSelectCard: null,
      imageCategorySrc: null,
      imageCategory: null,
      mobileImageCategorySelected: null,
      mobileImageCategorySrc: null,
      isActive: false,
      backgroundCategorySelected: null,
      backgroundCategorySrc: null,
      isBackgroundBlur: false,
      isBackgroundKenBurns: false,
      name: '',
      imageClicked: null,
      description: '',
      reference: '',
      mediaOriginSelected: 'BUK',
      mediaOriginOptions: [
        { value: 'BUK', text: this.$t('dataGeneric.internal') },
        { value: 'DIR', text: this.$t('dataGeneric.external') },
      ],
      imageCategorySelected: null,
      imageCategorySelectedPlaceholder: '',
      imageCategoryUrlSelected: '',
      mobileImageCategory: null,
      mobileImageCategoryPlaceholder: '',
      mobileImageCategoryURL: '',
      backgroundCategory: null,
      backgroundCategoryPlaceholder: '',
      backgroundMobileCategoryPlaceholder: '',
      backgroundCategoryURL: '',
      mostrarDatos: false,
      categorySuscriptions: null,
      cardSelected: null,
      cardMobileSelected: null,
      showData: false,
      showFiles: false,
      showDesing: false,
      showDesingTit: false,
      isTitle: false,
      backgroundMobileCategorySelected: false,
      backgroundMobileCategorySrc: null,
      contentDesign: 'NOT',
      contentDesignTypes: [
        { value: 'NOT', text: this.$t('designTypes.not') },
        { value: 'ONL', text: this.$t('designTypes.onl') },
        { value: 'CTC', text: this.$t('designTypes.ctc') },
        { value: 'TAD', text: this.$t('designTypes.tad') },
        {
          value: 'TDS',
          text: this.$t('designTypes.tds'),
        },
        { value: 'TID', text: this.$t('designTypes.tid') },
      ],
      selected: [],
      idTags: [],
      originalTags: [],
      option: [],
      friendlyUrl: null,
      friendlyUrlValue: null,
      seoTitle: null,
      seoDescription: null,
      showSeo: false,

    }
  },
  watch: {
    friendlyUrl() {
      this.friendlyUrlValue = this.friendlyUrl
        // eslint-disable-next-line no-useless-escape
        .replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ')
        .toLowerCase()

      // Corta los espacios al inicio y al final del this.friendlyUrling
      this.friendlyUrlValue = this.friendlyUrlValue.replace(/^\s+|\s+$/gm, '')

      // Reemplaza el espacio con guión
      this.friendlyUrlValue = this.friendlyUrlValue.replace(/\s+/g, '-')
    },
    imageCategory(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageCategorySelected = true
              this.imageCategorySrc = value
            })
            .catch(() => {
              this.imageCategorySrc = null
            })
        } else {
          this.imageCategorySrc = null
        }
      }
    },
    mobileImageCategory(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.mobileImageCategorySelected = true

              this.mobileImageCategorySrc = value
            })
            .catch(() => {
              this.mobileImageCategorySrc = null
            })
        } else {
          this.mobileImageCategorySrc = null
        }
      }
    },

    backgroundCategory(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.backgroundCategorySelected = true

              this.backgroundCategorySrc = value
            })
            .catch(() => {
              this.backgroundCategorySrc = null
            })
        } else {
          this.backgroundCategorySrc = null
        }
      }
    },

  },
  mounted() {
    this.getTags()
    this.fetch()
  },
  methods: {
    selectVendor(data) {
      this.hideModal()
      this.vendorId = data.id
      this.vendorName = data.vendorName
    },
    hideModal() {
      this.$refs.modalCard.hide()
      this.$refs['vend-modal'].hide()
    },
    showModalCard(type) {
      this.$refs.modalCard.show()
      switch (type) {
        case 'imageCatTV':
          this.idSelectCard = this.cardSelected

          break
        case 'imageContTV':
          this.idSelectCard = this.cardMobileSelected

          break

        default:
          break
      }
      this.typeCard = type
    },
    fetch() {
      const query = `
            query{        
                allProducts(id:"${this.id}") {                
                    edges {
                        node {
                        id
                        name
                        order
                        isSpecial
                        friendlyUrl
                        seoTitle
                        seoDescription
                        reference
                        state
                        isActive          
                        mediaLocation
                        imageUrl
                        imageMobileUrl
                        backgroundUrl
                        vendor{
                            id
                            vendorName
                        }
                        categories {
                        edges {
                            node {
                            id
                            name
                            }
                        }
                        }
                        tags {
                        edges {
                            node {
                            id
                            name
                            }
                        }
                        }        
                        description                    
                        price
                        priceOld
                        stock
                        width
                        weight
                        high
                        long
                        isBackgroundBlur
                        isBackgroundKenBurns
                        tags {
                            edges {
                                node {
                                    id
                                    name
                                }
                            }
                        }
                        client {
                        id
                        name
                        currency
                        }       
                        
                    }
                }
            }
        } `
      axios.post('', { query }).then(res => {
        messageError(res, this)

        const datos = res.data.data.allProducts.edges[0].node
        this.name = datos.name
        this.reference = datos.reference
        this.price = datos.price
        this.vendorId = datos.vendor != null ? datos.vendor.id : null
        this.vendorName = this.vendorId != null ? datos.vendor.vendorName : null
        this.isSpecial = datos.isSpecial

        this.seoTitle = datos.seoTitle
        this.seoDescription = datos.seoDescription
        this.friendlyUrl = datos.friendlyUrl

        this.currency = datos.client.currency
        this.isActive = datos.isActive
        this.description = datos.description
        this.isBackgroundBlur = datos.isBackgroundBlur
        this.isBackgroundKenBurns = datos.isBackgroundKenBurns
        this.contentStatus = datos.state
        this.labelStatus = datos.state

        this.imageCategorySelectedPlaceholder = datos.imageUrl
        try {
          this.imageCategorySelected = this.imageCategorySelectedPlaceholder.trim() !== ''
        // eslint-disable-next-line no-empty
        } catch (error) {

        }

        this.mobileImageCategoryPlaceholder = datos.imageMobileUrl
        try {
          this.mobileImageCategorySelected = this.mobileImageCategoryPlaceholder.trim() !== ''
        // eslint-disable-next-line no-empty
        } catch (error) {

        }

        this.backgroundCategoryPlaceholder = datos.backgroundUrl
        try {
          this.backgroundCategorySelected = this.backgroundCategoryPlaceholder.trim() !== ''
        // eslint-disable-next-line no-empty
        } catch (error) {

        }

        this.mediaOriginSelected = datos.mediaLocation

        this.mostrarDatos = true

        this.selected = []
        this.idTags = []
        this.originalTags = []
        datos.tags.edges.forEach(element => {
          this.selected.push(element.node.name)
          this.idTags.push(element.node.id)
          this.originalTags.push(element.node.name)
        })

        switch (this.mediaOriginSelected) {
          case 'DIR':
            this.imageCategory = datos.imageUrl
            this.mobileImageCategory = datos.imageMobileUrl
            this.backgroundCategory = datos.backgroundUrl
            break
          default:
            break
        }
        this.$emit('return', {
          name: this.name, viewName: this.viewName, viewDetail: this.viewDetail, state: this.labelStatus,
        })
      }).catch(err => {
        console.log(err)
      })
    },
    SelectCardId(file) {
      switch (this.typeCard) {
        case 'imageCatTV':
          this.cardSelected = file
          break
        case 'imageContTV':
          this.cardMobileSelected = file
          break
        default:
          break
      }
      this.hideModal()
    },
    updateProductsData(event) {
      this.showData = true
      event.preventDefault()

      this.isActive = !!(this.contentStatus === 'GRE' || this.contentStatus === 'YEW')
      axios
        .post('', {
          variables: {
            name: this.name,
            description: this.description,
            reference: this.reference,
            isActive: this.isActive,
            isBackgroundBlur: this.isBackgroundBlur,
            isBackgroundKenBurns: this.isBackgroundKenBurns,
            state: this.contentStatus,
            vendor: this.vendorId,
            price: this.price,
            isSpecial: this.isSpecial,

          },
          query: `
            mutation($price: Float,$name: String, $description: String,
            $vendor: ID,$reference: String,$state:ProductStateInput,
            $isActive: Boolean,$isBackgroundBlur: Boolean,
            $isSpecial: Boolean,$isBackgroundKenBurns: Boolean) {
                updateProductsShop(id: "${this.id}",input:{
                    name: $name,
                    description: $description,
                    reference: $reference,
                    isActive: $isActive,
                    isBackgroundBlur: $isBackgroundBlur,
                    isBackgroundKenBurns: $isBackgroundKenBurns,
                    state: $state,
                    isSpecial: $isSpecial,
                    price: $price,
                    vendor: $vendor,
                    tags: [${this.idTags}],
                }
                
                ){
                    product{
                        id
                        name
                        description
                        reference
                        isActive                
                }
                }
            }
            `,
        })
        .then(res => {
          messageError(res, this)

          this.labelStatus = this.contentStatus
          showToast(
            this.$t('product.success', { productName: res.data.data.updateProductsShop.product.name }),
            1, this,
          )
          this.showData = false
          // this.getCategoryInfo();
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('product.errorUpdate'), 2, this)
          this.showData = false
        })
    },
    goBack() {
      window.history.back()
    },

    chooseImage(imageClicked) {
      this.imageClicked = imageClicked
      if (this.mediaOriginSelected === 'BUK') {
        document.getElementById(imageClicked).click()
      } else if (this.mediaOriginSelected === 'DIR') {
        this.$swal({
          title: 'Indicar url',
          input: 'url',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          inputAttributes: {
            autocapitalize: 'off',
          },
          showCancelButton: true,
          confirmButtonText: this.$t('dataGeneric.save'),
          cancelButtonText: this.$t('dataGeneric.cancel'),
          showLoaderOnConfirm: true,
        }).then(result => {
          if (result.value) {
            this.processImageData(result.value)
          }
        })
      }
    },
    onChange() {
      this.imageClicked = ''
      this.imageCategory = null
      this.mobileImageCategory = null
      this.backgroundCategory = null
      this.imageCategorySelected = false
      this.mobileImageCategorySelected = false
      this.backgroundCategorySelected = false
    },
    processImageData(data) {
      switch (this.imageClicked) {
        case 'fileInput':
          this.imageCategory = data
          this.imageClicked = ''
          break
        case 'fileInputMob':
          this.mobileImageCategory = data
          this.imageClicked = ''
          break

        case 'fileInputBck':
          this.backgroundCategory = data
          this.imageClicked = ''
          break

        default:
          break
      }
      this.hideModal()
    },
    updateProductsFiles(event) {
      this.showFiles = true

      event.preventDefault()
      if (this.mediaOriginSelected === 'DIR') {
        axios
          .post('', {
            variables: {
              imageUrl: this.imageCategory,
              imageMobileUrl: this.mobileImageCategory,
              backgroundUrl: this.backgroundCategory,
            },
            query: `
                            mutation($imageUrl: String, $imageMobileUrl: String, $backgroundUrl: String) {
                            updateProductsShop(id: "${this.id}", input:{
                                imageUrl: $imageUrl,
                                imageMobileUrl: $imageMobileUrl,
                                backgroundUrl: $backgroundUrl,
                                mediaLocation: DIR
                                }
                                ){
                                product{
                                id
                                name
                                
                                }
                            }
                            }
                        `,
          })
          .then(res => {
            messageError(res, this)

            showToast(
              this.$t('product.success', { productName: res.data.data.updateProductsShop.product.name }),
              1, this,
            )
            this.showFiles = false
          })
          .catch(() => {
            this.showFiles = false

            showToast(this.$t('error'), 2, this)
          })
        this.showFiles = false
      } else {
        this.processBucketFiles()
      }
    },
    processBucketFiles() {
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      const query = `
            mutation{
                updateProductsShop(id:"${this.id}",input:{
                    mediaLocation: BUK
                }
                ){
                    product{
                        id
                        name                
                    }
                    }
                }
            `
      data.append('query', query)
      this.deleteFile.forEach(element => {
        switch (element) {
          case 'fileInput':
            data.append('deleteFile', 'image')

            break
          case 'fileInputMob':
            data.append('deleteFile', 'image_mobile')

            break
          case 'fileInputBck':
            data.append('deleteFile', 'background')

            break

          default:
            break
        }
      })
      if (this.imageCategorySelected) data.append('file', this.imageCategory)
      if (this.mobileImageCategorySelected) data.append('fileMobile', this.mobileImageCategory)
      if (this.backgroundCategorySelected) data.append('fileBackground', this.backgroundCategory)

      axios.post('', data, config).then(createResponse => {
        messageError(createResponse, this)

        this.showFiles = false

        showToast(this.$t('code.updateData'), 1, this)
      }).catch(err => {
        console.log(err)
        this.showFiles = false

        showToast(this.$t('code.updateDataError'), 2, this)
      })
    },

    deleteImage(type) {
      this.$swal({
        title: this.$t('removData'),
        text: this.$t('sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            this.deleteFile.push(type)
            switch (type) {
              case 'fileInput':
                this.imageCategory = null
                this.imageCategorySelected = false

                break
              case 'fileInputMob':
                this.mobileImageCategory = null
                this.mobileImageCategorySelected = false

                break
              case 'fileInputBck':
                this.backgroundCategory = null
                this.backgroundCategorySelected = false

                break
              default:
                break
            }
          }
        })
        .catch(() => { })
    },
    modalTags() {
      if (this.selected.length > this.idTags.length) {
        let id = ''
        axios
          .post('', {
            query: `
      {
        allTags(name:"${this.selected[this.selected.length - 1]}") {
          edges {
            node {
              id
              name
              createdAt

            }
          }
        }
      }
    `,
          })
          .then(result => {
            messageError(result, this)

            let crear = true
            result.data.data.allTags.edges.forEach(element => {
              if (
                element.node.name.toLowerCase()
                === this.selected[this.selected.length - 1].toLowerCase()
              ) {
                id = element.node.id
                crear = false
              }
            })

            if (crear) {
              /* AQUÍ CREA EL TAG */

              axios
                .post('', {
                  query: `
          mutation{
            createTag(input:{name:"${this.selected[this.selected.length - 1]
}"}){
                tag{
                    id
                    name
                }
            }
        }
        `,
                })
                .then(res => {
                  messageError(res, this)

                  this.idTags.push(res.data.data.createTag.tag.id)
                })
                .catch(() => { })
            } else {
              this.idTags.push(id)
              // console.log(this.idTags);
            }
          })
          .catch(() => { })
      } else {
        // console.log(this.selected);
        this.originalTags.forEach((a, index) => {
          let quitar = true
          this.selected.forEach(element => {
            if (element.toLowerCase() === a.toLowerCase()) {
              quitar = false
            }
          })
          if (quitar) {
            this.idTags.splice(index, 1)
            this.originalTags.splice(index, 1)
          }
        })
      }
    },
    getTags() {
      axios
        .post('', {
          query: `
      {
        allTags {
          edges {
            node {
              id
              name
              createdAt

            }
          }
        }
      }
    `,
        })
        .then(result => {
          messageError(result, this)

          result.data.data.allTags.edges.forEach(element => {
            this.option.push({
              title: element.node.name,
              value: element.node.id,
            })
          })
        })
        .catch(() => { })
    },
    updateSeo(event) {
      this.showSeo = true
      event.preventDefault()
      const { id } = this.$route.params

      axios
        .post('', {
          variables: {
            title: this.seoTitle,
            description: this.seoDescription,
            friendlyUrl: this.friendlyUrlValue,
          },
          query: `
        mutation($title: String,$description: String,$friendlyUrl: String){
          updateProductsShop(id:"${id}", input:{
          seoTitle: $title,
          seoDescription: $description,
          friendlyUrl: $friendlyUrl
          }){
            product{
            id
            seoTitle
            seoDescription
            friendlyUrl
          }
        }
      }
    `,
        })
        .then(res => {
          messageError(res, this)

          showToast(
            this.$t('code.cat', {
              data: res.data.data.updateProductsShop.product.name,
            }),
            1, this,
          )

          this.showSeo = false
        })
        .catch(() => {
          showToast(this.$t('code.updateDataError'), 2, this)
          this.showSeo = false
        })
    },
    newTag(data) {
      this.option.push({
        title: data.name,
        value: data.id,
      })
      this.selected.push({
        title: data.name,
        value: data.id,
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "vue-context/src/sass/vue-context";

#edit-products-shop .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 200px;
}

#edit-products-shop #add-new-category-sidebar {
  #edit-products-shop .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

#edit-products-shop .file-input {
  display: none;
}

#edit-products-shop .final-info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  font-weight: bold;
  color: aquamarine;
  font-size: smaller;
}

.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__clear,
.vs--disabled .vs__search,
.vs--disabled .vs__selected,
.vs--disabled .vs__open-indicator {
  cursor: not-allowed;
  background-color: transparent !important;
  opacity: 0.8 !important;
  /* background-color: #f8f8f8; */
}
</style>
